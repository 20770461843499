.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.logo {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.highlight {
    padding: 9px 14px;
    margin-bottom: 14px;
    background-color: #f7f7f9;
    border: 1px solid #e1e1e8;
    border-radius: 4px;
}

.dropzone-container {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;

}

.dropzone {
    display: flex;
    text-align: center;
    align-items: center;
    width: 100%;
    height: 100px;
    border: 1px dashed black;
}

.dropzone p {
    width: 100%;
    text-align: center;
    font-weight: bold;
}
